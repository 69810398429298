<template>
    <div>
        <div class="qianliang-header">
            <div class="qianliang-card-header" style="padding-left: 19px;">
                <img src="../../assets/img/qianliang/withdraw/riFill.png" class="qianliang-card-icon">
                <div style="display: flex;flex: 1 1 auto;">
                    <div style="flex: 1 1 auto;text-align: left;">交易价格</div>
                    <div style="flex: 0 0 auto">{{now}}</div>
                </div>
            </div>
            <div style="background: #fff;margin: 0 24px;padding:11px 0;font-size: 18px;border-radius: 15px;">1 黑钻 = {{account.liangpiaoRadio}} 元人民币</div>
        </div>
        <van-cell-group inset class="qianliang-card" v-if="userInfo.headImg">
            <div class="qianliang-card-header" style="display:flex;align-items: center;">
                <img src="../../assets/img/qianliang/withdraw/antOutline-account-book@1x.png" class="qianliang-card-icon">
                <div class="qianliang-card-title" style="display: flex;align-items: center;">
                    <span style="margin-right: 32px;">收款账户</span>
                    <img :src="userInfo.headImg" style="margin-right: 2px;height: 20px;width: 20px;border-radius: 50%">
                    <span style="color: #101010;font-size: 14px;">{{userInfo.wxNickname}}</span>
                </div>
                <div style="color: #BBBBBB;font-size: 14px;">24小时内到账</div>
            </div>
            <div class="qianliang-card-body" style="color: #BBBBBB;font-size: 13px;">提现通过微信红包发送，需到公众号中领取红包！</div>
        </van-cell-group>
        <van-cell-group inset class="qianliang-card">
            <div class="qianliang-card-header">
                <img src="../../assets/img/qianliang/withdraw/iconPark-finance@1x.png" class="qianliang-card-icon">
                <div class="qianliang-card-title">提现金额</div>
            </div>
            <div class="qianliang-card-body">
                <div style="display: flex;font-size: 16px;">
                    <van-field v-model="price" placeholder="请输入交易黑钻数量" class="qianliang-input" />
                    <div style="color: #101010;">黑钻 = {{ (price * parseInt(account.liangpiaoRadio * 100))/100.00}}元人民币</div>
                </div>
                <div style="color: #101010">
                    当前黑钻余额{{account.canLiangpiaoNum}}， <span style="color: rgb(22, 132, 252)" @click="numAll">全部提现</span>
                </div>
            </div>
        </van-cell-group>
        <div style="padding:0 14px;">
            <van-button size="large" style="border-radius: 4px 4px 4px 4px;background-color: #FEC919;color: #000;border:none;" :disabled="!(price>=5)" @click="withDrawAction">提现</van-button>
        </div>
        <div class="qianliang-card">
            <div class="qianliang-card-header">
                <img src="../../assets/img/qianliang/withdraw/iconPark-info@1x.png" class="qianliang-card-icon">
                <div class="qianliang-card-title">提现说明</div>
            </div>
            <div class="qianliang-card-body" style="padding-left: 8px;">
                <div style="color: rgba(187, 187, 187, 1);font-size: 13px;">1、单次最低提现金额为5黑钻</div>
                <div style="color: rgba(187, 187, 187, 1);font-size: 13px;">2、请务必关注公众号，以免收不到红包</div>
                <div style="color: rgba(187, 187, 187, 1);font-size: 13px;">3、微信必须实名认证，否则会提现失败</div>
                <div style="color: rgba(187, 187, 187, 1);font-size: 13px;">4、如遇其他问题，请联系群主</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "withdraw",
    data(){
        return {
            price:'',
            now: '',
            account:{
                canLiangpiaoNum: 0,
                frozenLiangpiaoNum: 0,
                inLiangpiaoNum: 0,
                liangpiaoRadio: 0,
                outLiangpiaoNum: 0,
            },
            userInfo:{
                headImg:'',
                wxNickname:'',
            },
        }
    },
    watch:{
        price(newValue){
            console.log(newValue)
            if(!newValue){
                this.price = ''
                return
            }
            if(newValue > this.account.canLiangpiaoNum){
                this.price = this.account.canLiangpiaoNum
                return
            }
            this.price = parseInt(newValue)
        }
    },
    created() {
        if(!localStorage.getItem('xToken') || !localStorage.getItem('userInfo')){
            this.$router.replace('/verify')
        }
    },
    mounted() {
        this.loadData()
        this.now = this.getTime()
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    },
    methods: {
        loadData() {
            let that = this
            // 查询账户余额
            this.post('/member/account/queryRemainAccount').then(result => {
                if (result.respCode === "0") {
                    // console.log(result.data)
                    that.account = result.data
                }
            })
        },
        getTime() {
            let date = new Date(),
                year = date.getFullYear(),
                month = date.getMonth() + 1,
                day = date.getDate(),
                hour = date.getHours() < 10 ? "0" + date.getHours() : date.getHours(),
                minute = date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes(),
                second = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
            month >= 1 && month <= 9 ? (month = "0" + month) : "";
            day >= 0 && day <= 9 ? (day = "0" + day) : "";
            return year + '.' + month + '.' + day + ' ' + hour + ':' + minute + ':' + second;
        },
        numAll(){
            this.price = this.account.canLiangpiaoNum
        },
        // 提现
        withDrawAction(){
            let that = this
            this.post('/member/withdrawal/apply',{
                amountType: 4,
                outLiangpiaoNum: this.price,
                outcomeAmount: (this.price * parseInt(this.account.liangpiaoRadio * 100))/100.00
            }).then(result => {
                if(result.respCode === "0"){
                    that.$dialog.alert({
                        title: '提示',
                        message: "提现申请成功，微信红包将在24小时内到账",
                    }).then(()=>{
                        that.loadData()
                        that.price = ""
                    })
                } else {
                    that.$dialog.alert({
                        title: '提示',
                        message: result.respMsg,
                    }).then(()=>{
                        that.loadData()
                        that.price = ""
                    })
                }
            })
        },
    }
}
</script>

<style scoped>
.qianliang-input{
    padding: 0;
    width: 150px;
}
.qianliang-input input{
    text-align: center;
}
.van-cell::after{
    content: none;
}
</style>
